// reference_slider max 36rem

.reference_slider {

  .reference_slider_container {

    .reference_slider_items {

      .reference_slider_item {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
      }
    }
  }
}