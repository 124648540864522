// team max 36rem

.team {

  .team_container {

    .team_member {
      width: 24rem;
      max-width: 100%;
      max-height: 100vw;
      height: 24rem;
      margin: 0 auto;

      .team_member_overlay {

        .team_member_overlay_text {

          h3 {
            font-size: 1.25rem;
          }

          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
}