// bulletpoints max 36rem

.bulletpoints {

  .bulletpoints_container {

    .bulletpoints_items {

      .bulletpoints_item {
        max-width: 28rem;
        width: 100%;
        margin-left: auto;
        margin-right: auto;

        &:nth-of-type(odd) {
          -ms-flex-direction: row;
          flex-direction: row;
          -ms-flex-pack: start;
          justify-content: start;

          .bulletpoints_item_text {
            padding-left: 1.25rem;
            padding-right: 0;

            h3, p {
              text-align: left;
            }
          }
        }

        &:nth-of-type(even) {
          padding-left: 0;
        }
      }
    }
  }
}