// basic styles
//@import "style_base.scss";

// breakpoint max-width: 36rem
@media screen and (max-width: 36rem) {
  @import "ContentElements/blog/blog_max_36rem.scss";
  @import "ContentElements/blog_detail/blog_detail_max_36rem.scss";
  @import "ContentElements/blog_overview/blog_overview_max_36rem.scss";
  @import "ContentElements/bulletpoints/bulletpoints_max_36rem.scss";
  @import "ContentElements/gallery/gallery_max_36rem.scss";
  @import "ContentElements/logo_gallery/logo_gallery_max_36rem.scss";
  @import "ContentElements/reference_slider/reference_slider_max_36rem.scss";
  @import "ContentElements/team/team_max_36rem.scss";
}
