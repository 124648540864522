// blog_detail max 36rem

.blog_detail {

  .blog_header {

    h1 {
        font-size: 2rem;

      span {
        font-size: 1rem;
      }
    }
  }

  .blog_detail_container {
    padding: 0 1rem;

    .blog_detail_part {

      .blog_detail_imageonly {

        &.imagedivider {
          max-width: calc(100% + 2rem);
          margin-left: -1rem;
          margin-right: -1rem;
        }
      }

      .blog_detail_quoteonly {

        p {
          font-size: 1.5rem;
        }
      }

      .blog_detail_author {
        -ms-flex-direction: column;
        flex-direction: column;

        img, .dummy {
          margin-top: 1rem;
          margin-bottom: 3rem;
        }

        .text {
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }
}