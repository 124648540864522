// blog_overview max 36rem

.blog_overview {

  .blog_overview_blocks {

    .blog_overview_block {

      .blog_overview_blocks_content {
        padding: 10rem 1.5rem 6.5rem;
      }
    }
  }
}