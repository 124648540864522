//logo_gallery max 36rem

.logo_gallery {

  .logo_gallery_container {

    .logo_gallery_images {
      margin-top: 3rem;
    }
  }
}