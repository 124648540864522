// blog max 36rem

.blog {

  .blog_blocks {
    height: auto;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: 90vw 60vw 60vw 60vw;
    grid-template-rows: 90vw 60vw 60vw 60vw;

    .blog_block {
      margin: 0.8rem 0;
    }

    .blog_block:nth-child(1) {
      -ms-grid-column: 1;
      -ms-grid-column-span: 1;
      -ms-grid-row: 1;
      grid-column: 1/2;
      grid-row: 1/2;
    }

    .blog_block:nth-child(2) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
      -ms-grid-row-span: 1;
      grid-column: 1/2;
      grid-row: 2/3;
    }

    .blog_block:nth-child(3) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
      grid-column: 1/2;
      grid-row: 3/4;
    }

    .blog_block:nth-child(4) {
      -ms-grid-column: 1;
      -ms-grid-row: 4;
      grid-column: 1/2;
      grid-row: 4/5;
    }
  }
}